exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-domain-verkauf-js": () => import("./../../../src/pages/domain-verkauf.js" /* webpackChunkName: "component---src-pages-domain-verkauf-js" */),
  "component---src-pages-hersteller-bestaetigung-js": () => import("./../../../src/pages/hersteller/bestaetigung.js" /* webpackChunkName: "component---src-pages-hersteller-bestaetigung-js" */),
  "component---src-pages-hersteller-erfahrungsbericht-kommentar-js": () => import("./../../../src/pages/hersteller/erfahrungsbericht-kommentar.js" /* webpackChunkName: "component---src-pages-hersteller-erfahrungsbericht-kommentar-js" */),
  "component---src-pages-hersteller-erfahrungsbericht-verfassen-js": () => import("./../../../src/pages/hersteller/erfahrungsbericht-verfassen.js" /* webpackChunkName: "component---src-pages-hersteller-erfahrungsbericht-verfassen-js" */),
  "component---src-pages-hersteller-frage-an-hersteller-js": () => import("./../../../src/pages/hersteller/frage-an-hersteller.js" /* webpackChunkName: "component---src-pages-hersteller-frage-an-hersteller-js" */),
  "component---src-pages-hersteller-js": () => import("./../../../src/pages/hersteller.js" /* webpackChunkName: "component---src-pages-hersteller-js" */),
  "component---src-pages-hersteller-services-js": () => import("./../../../src/pages/hersteller-services.js" /* webpackChunkName: "component---src-pages-hersteller-services-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-templates-hersteller-detail-template-js": () => import("./../../../src/templates/hersteller-detail-template.js" /* webpackChunkName: "component---src-templates-hersteller-detail-template-js" */),
  "component---src-templates-overview-wp-article-template-js": () => import("./../../../src/templates/overview-wpArticle-template.js" /* webpackChunkName: "component---src-templates-overview-wp-article-template-js" */),
  "component---src-templates-wp-article-template-js": () => import("./../../../src/templates/wpArticle-template.js" /* webpackChunkName: "component---src-templates-wp-article-template-js" */)
}

